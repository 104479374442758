import React from "react";
import { sendTagOnContactCall } from "app/utils/analytics";
import "./header.scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isProductPage } from "app/utils/urlDataExtractor";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import IconPhone from "app/pages/.shared/static/icons/IconPhoneBordered";
import { getLogoURL } from "src/@brand/appConstants";
import get from "lodash/get";
import { BackButton } from "app/pages/.shared/BackButton/BackButton";

const Header = ({
	dialPhone,
	match,
	merchandisingCode,
	shop,
	fallbackRedirectUrl,
	useBrowserBackOnProductPage,
}) => {
	const isFicheProduitPage = isProductPage(match.path);

	return (
		<header className="header">
			<section className="header__content">
				<div className="header__back">
					{isFicheProduitPage && (
						<BackButton
							forceBrowserBack={useBrowserBackOnProductPage}
							to={
								merchandisingCode
									? {
											pathname: "/merch",
											search: `?code=${merchandisingCode}`,
											merchCode: merchandisingCode,
									  }
									: {
											pathname: fallbackRedirectUrl,
									  }
							}
							label=""
						/>
					)}
				</div>
				<div className="header__brand">
					<a className="relative-link" href={getLogoURL(shop.slice(0, 2))}>
						<LogoBrandContainer isWhite={true} />
					</a>
				</div>
				<div className="header__phone">
					<a
						className="header__phone-button"
						onClick={sendTagOnContactCall}
						href={`tel:${dialPhone}`}
					>
						<IconPhone width={25} height={25} />
					</a>
				</div>
			</section>
		</header>
	);
};

Header.propTypes = {
	dialPhone: PropTypes.string,
	match: PropTypes.object,
	merchandisingCode: PropTypes.string,
	shop: PropTypes.string,
	fallbackRedirectUrl: PropTypes.string,
	useBrowserBackOnProductPage: PropTypes.bool,
};

Header.defaultProps = {
	match: {},
};

const mapStateToProps = state => {
	return {
		merchandisingCode: get(state, "merchandising.current.code"),
		shop: state.shop,
	};
};

export default connect(mapStateToProps)(Header);
